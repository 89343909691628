/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function SPLASH() {
  return (
    <>
      <div
        style={{
          position: "absolute", // Position the text absolutely
          bottom: "10px", // 10px from the bottom
          right: "10px", // 10px from the right
          color: "white", // Change the text color based on your background image
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: adds a semi-transparent background for better visibility
          padding: "5px", // Adds some padding around the text
          borderRadius: "5px", // Optional: rounds the corners of the background
          fontSize: "0.75rem", // Smaller text size
        }}
      >
        Photo by{" "}
        <a
          href="https://unsplash.com/@pooorliza?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          target="_blank"
          rel="noopener noreferrer"
        >
          Liza Pooor
        </a>{" "}
        on{" "}
        <a
          href="https://unsplash.com/photos/man-riding-horse-statue-during-daytime-T0mJZ9xoiuc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unsplash
        </a>
      </div>
    </>
  );
}

export default SPLASH;
