/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

//Photo by <a href="https://unsplash.com/@pooorliza?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Liza Pooor</a> on <a href="https://unsplash.com/photos/man-riding-horse-statue-during-daytime-T0mJZ9xoiuc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
import bgImage from "assets/images/liza-pooor-T0mJZ9xoiuc-unsplash.jpg";
import pairingImage from "assets/images/ZwiftPairing.png";
import runningImage from "assets/images/ZwiftRunning.png";
import appImage from "assets/images/TSSApp.png";
import SPLASH from "../Splash";

function TSSV2() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "start center",
          paddingTop: "10vh",
        }}
      >
        <SPLASH />
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            mx="auto"
            style={{
              padding: "70px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <MKTypography
              variant="h1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Treadmill Smart Speed
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="left"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Treadmill Smart Speed is designed to communicate your Treadmill Running Speed from
              your iPhone a receiving devices fitness app. It uses the same Bluetooth communications
              protocols as expensive running foot pods. It was originally designed to be used for
              Zwift
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="left"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              <li>Start up TSS on your iPhone</li>
              <li>Start Zwift on seperate Device</li>
              <li>Goto Zwift Run Pairing Screen</li>
              <li>Pair with TSS</li>
              <li>Start Running</li>
              <li>Match Treadmill Speed to TSS Speed</li>
              <a
                href="https://apps.apple.com/us/app/treadmill-smart-speed-v2/id6479820177"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", fontWeight: "bold" }}
              >
                Treadmill Smart Speed V2
              </a>
            </MKTypography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <img
                src={pairingImage}
                alt="Zwift Pairing"
                style={{ maxWidth: "50%", marginRight: "1rem" }}
              />
              <img
                src={runningImage}
                alt="Zwift Running"
                style={{ maxWidth: "50%", marginRight: "1rem" }}
              />
              <img
                src={appImage}
                alt="Treadmill Smart Speed App"
                style={{ maxWidth: "50%", marginRight: "1rem" }}
              />
            </div>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default TSSV2;
