/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
// import Counters from "pages/Presentation/sections/Counters";
// import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
// import Pages from "pages/Presentation/sections/Pages";
// import Testimonials from "pages/Presentation/sections/Testimonials";
// import Download from "pages/Presentation/sections/Download";

// Presentation page components
// import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
//import bgImage from "assets/images/bg-presentation.jpg";
//import bgImage from "assets/images/liza-pooor-Gr5RqqIdsgo-unsplash.jpg";
// Photo by <a href="https://unsplash.com/@pooorliza?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Liza Pooor</a> on <a href="https://unsplash.com/photos/statue-of-man-riding-horse-near-white-concrete-building-during-daytime-Gr5RqqIdsgo?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>

//Photo by <a href="https://unsplash.com/@pooorliza?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Liza Pooor</a> on <a href="https://unsplash.com/photos/man-riding-horse-statue-during-daytime-T0mJZ9xoiuc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
import bgImage from "assets/images/liza-pooor-T0mJZ9xoiuc-unsplash.jpg";
import SPLASH from "../Splash";
function Support() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "start center",
          paddingTop: "10vh",
        }}
      >
        <SPLASH />
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            mx="auto"
            style={{
              padding: "70px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Support : email me support@appyappster.com
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Support;
