/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

//Photo by <a href="https://unsplash.com/@pooorliza?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Liza Pooor</a> on <a href="https://unsplash.com/photos/man-riding-horse-statue-during-daytime-T0mJZ9xoiuc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
import bgImage from "assets/images/liza-pooor-T0mJZ9xoiuc-unsplash.jpg";
import SPLASH from "../Splash";

function TSS() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "start center",
          paddingTop: "10vh",
        }}
      >
        <SPLASH />
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            mx="auto"
            style={{
              padding: "70px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <MKTypography
              variant="h1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Treadmill Smart Speed
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="left"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Treadmill Smart Speed was built just after Zwift Running was first launched in 2017.
              It has served it purpose in but the code is old, it is overly complicate so this will
              be sunset very soon. Please check out{" "}
              <a
                href="https://apps.apple.com/gb/app/treadmill-smart-speed/id1193076459"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", fontWeight: "bold" }}
              >
                Treadmill Smart Speed
              </a>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default TSS;
